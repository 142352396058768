





























import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Profile } from 'client-website-ts-library/types';
import { Component, Mixins } from 'vue-property-decorator';

import SteppedForm from '../components/Forms/SteppedForm.vue';

@Component({
  components: {
    SteppedForm,
  },
})
export default class Survey extends Mixins(View) {
  private agent: Profile | null = null;

  submitted = false;

  mounted() {
    API.Profiles.Get(Config.Website.Settings!.WebsiteId).then((p) => {
      this.agent = p;
    });
  }
}
